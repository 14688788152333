<script setup>
import { ref } from "vue"
import { useActivitiesStore } from "@/store/activity"

const activityStore = useActivitiesStore()

const activities = ref([])
const loading = ref(true)
const totalRecords = ref(0)
const filter = ref(null)
const isRegister = ref('all')
const isActive = ref('all')
const optionRegister = [
	{ name: "All", value: 'all' },
	{ name: "Open Register", value: 1 },
	{ name: "Close Register", value: 0 }
]

const optionActive = [
	{ name: "All", value: 'all' },
	{ name: "Active", value: 1 },
	{ name: "Deactive", value: 0 }
]

const search = (e) => {
  loading.value = true

	const page = typeof e !== 'undefined' ? (e.page || 0) + 1 : 1
  activityStore.index({ page, option: { isRegister: isRegister.value, isActive: isActive.value, filter: filter.value } }).then((res) => {
    activities.value = res.data.activities.data
		totalRecords.value = res.data.activities.total

    loading.value = false
  })
}

const clearFilter = () => {
	filter.value = null
	isRegister.value = 'all'
	isActive.value = 'all'
	search()
}

search()
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
				<h5>Activities Index <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'activities.create' })" /></h5>
				<DataTable
					class="p-datatable-gridlines"
					dataKey="id"
					filterDisplay="row"
					:loading="loading"
					:lazy="true"
					:paginator="true"
					:rowHover="true"
					:rows="10"
					:totalRecords="totalRecords"
					:value="activities"
					@page="search($event)"
				>
					<template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
							<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-6">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="Heading, Registration, Compete, Rewards" />
									<Dropdown v-model="isRegister" optionLabel="name" optionValue="value" :options="optionRegister" />
									<Dropdown v-model="isActive" optionLabel="name" optionValue="value" :options="optionActive" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
						</div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

					<Column field="title_heading" header="Heading" />
					<Column field="title_registration" header="Registration" />
					<Column field="title_compete" header="Compete" />
					<Column field="title_rewards" header="Rewards" />
					<Column header="Register">
						<template #body="{data}">
							<Chip v-if="data.is_register" label="Register" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="Register" icon="pi pi-times" class="custom-chip-danger" />
						</template>
					</Column>
					<Column header="Active">
						<template #body="{data}">
							<Chip v-if="data.is_active" label="Active" icon="pi pi-check" class="custom-chip-success" />
              <Chip v-else label="Active" icon="pi pi-times" class="custom-chip-danger" />
						</template>
					</Column>
					<Column header="Options">
            <template #body="{data}">
              <router-link :to="`/activities/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>

              <router-link :to="`/activities/${data.id}/edit`">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
              </router-link>

							<router-link :to="`/activities/${data.id}/members`">
								<Button icon="pi pi-book" class="p-button-rounded p-button-primary mr-2 mb-2" />
							</router-link>
            </template>
          </Column>
				</DataTable>
      </div>
    </div>
  </div>
</template>
